import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="White Composite Fillings – Cosmetic Dentistry | The Smile Stylist" 
    description="At the Smile Stylist we use natural white composite resin to naturally restore your decayed and broken teeth.  Book your appointment with the GTA’s top cosmetic dentist." 
    keywords="Cosmetic dentistry" />
    <main class="site-content" role="main">
    <section id="main-top-img" class="white-fillings hidden-xs">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 sec-title wow animated fadeInDown">
            <h1>White Fillings</h1>
            <h2>Most patients elect to get white composite fillings instead of silver amalgam fillings</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 wow animated fadeInRight">

            <p><h2><a href="/white-fillings">White Fillings</a></h2></p>

            <p class="lead-black-bold">Most of us have developed a cavity at some point and had it filled with a tooth-colored composite or silver amalgam material. When a patient develops a cavity, the decayed areas of the tooth must be removed. The empty space that is left is then filled in with strong and durable dental materials to provide the tooth with the support it needs to function normally.</p>
            <p>In the past, patients fillings were made of a silver amalgam material. Today, patients also have the option of choosing composite, tooth-colored filling materials that seamlessly blend in with surrounding teeth. Here are the advantages and disadvantages of composite and amalgam fillings.</p>

            <p>Most patients elect to get white composite fillings instead of silver amalgam fillings due to their many benefits, including:</p>
            <ul>
              <li>No health risks associated with the use of composite fillings</li>
              <li>Patients report less post-treatment discomfort and sensitivity when composite materials are used instead of silver materials</li>
              <li>The tooth-colored material can be matched to blend in with surrounding teeth</li>
            </ul><p>Although most of us prefer composite fillings, there are some disadvantages that they should be aware of:</p>
            <ul>
              <li>Tooth-colored fillings are slightly more expensive than silver fillings</li>
              <li>The use of composite materials requires more skill on the part of the dentist; this results in higher prices and highlights the importance of choosing a qualified cosmetic dentist to perform your procedure</li>
            </ul><h2>Amalgam Fillings</h2>
            <p>Silver fillings are still offered by most dentists. Some patients prefer silver fillings to composite fillings for several reasons:</p>
            <ul>
              <li>The silver amalgam treatment takes less time</li>
              <li>Silver fillings do not require use of advanced dental tools or techniques, lowering the cost of treatment</li>
              <li>They have been used for decades and have a solid long-term safety record</li>
            </ul><p>However, there are some drawbacks associated with silver amalgam fillings, including:</p>
            <ul>
              <li>Temperature changes in the mouth cause the silver fillings to expand and contract over a period of time; this damages the integrity of the tooth</li>
              <li>A greater amount of the natural tooth structure must be removed in order to prepare the tooth for the silver filling material</li>
              <li>The silver amalgam material is noticeable and a clear indicator that dental work has been done</li>
              <li>Some patients are concerned about the potential dangers of the mercury that is found in amalgam fillings</li>
            </ul>
            <p>It is important to schedule regular dental appointments so cavities can be detected and treated as soon as possible. We treat cavities with fillings and all ceramic crowns in Oakville from the comfort of our office in the downtown Oakville. Contact The Smile Stylist today to set up a consultation with Dr. Kaloti.</p>
            <p><a class="btn btn-red" href="/contact">Book Your Appointment</a></p>
          </div>
          <div class="col-sm-4 wow animated zoomIn text-center service__shadow-imgs" data-wow-delay="0.4s">

            <p>
              <img src={ require("../images/desktop/services/WhiteFillings.jpg" )} alt="White Fillings Before and After " class="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/WhiteFillings2.jpg" )} alt="Dental Fillings Before and After " class="img-responsive" />
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage